import React, { Fragment } from 'react'

import { useQueryClient } from 'react-query'

import { useTranslation } from 'react-i18next'

import {
  useSelector,
} from 'react-redux'

import Area from '_/components/layout/Area'
import { ResponsiveView as View } from '_/components/layout/View'
import Row from '_/components/layout/Row'

import Heading from '_/components/layout/Heading'
import TextBox from '_/components/layout/TextBox'
import InfoBlock from '_/components/layout/InfoBlock'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'
import NavTitle from '_/components/navigation/NavTitle'
import ActionCard from '_/components/action/ActionCard'

import { DetailsBox as Summary } from '_/templates/Summary'

import {
  formatCurrency,
} from '_/libs/basketCalculations'
import { usePageView } from '_/hooks/usePageView'

const mobileDetails = [
  'title',
  'subtitle',
  'paragraph_1',
  'paragraph_2',
  'paragraph_3',
  'info',
  // 'info_1',
  // 'info_2',
  // 'info_3',
  // 'info_4',
  'basket',
  'login',
  'home',
]

const desktopDetails = [
  'title',
  'subtitle',
  'paragraph_1',
  'paragraph_2',
  'paragraph_3',
  'info',
  // 'info_1 info_2 info_3 info_4',
  'login/2 . .',
  'home/2 . .',
]

const Confirmation = props => {
  const order = props.location.state

  /**
   * For 'Pay Later' orders, we simply extend the expiry of the cart
   * and email the customer a link to pay. Due to this, the 'order' is
   * actually a cart in draft status, with _nothing_ paid yet.
   */
  const isPayLater = order.status === 'draft' && order.pricing.paid === 0

  const user = useSelector(({ user }) => {
    return user
  })

  const { t } = useTranslation()

  const queryClient = useQueryClient()

  queryClient.removeQueries('cart')
  queryClient.removeQueries('customer')

  const {
    app,
  } = window.TICKNOVATE_CONFIG

  usePageView('ORDER_CONFIRMATION', { order })

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <Layout mobile={mobile}>
            <NavTitle mobile={mobile} title={t('confirm.section-title')} area={'nav'} />
            <View mobile={mobile} area={'form'} gridAlign={'stretch'}>
              <Area
                areas={mobile ? mobileDetails : desktopDetails}
                columns={mobile ? 1 : 4}
                rowgap={1}
                baseColUnit={1}
                margin={'0 0 3rem 0'}
              >
                {!isPayLater && (
                  <Fragment>
                    <Heading level={2} title={t('confirm.message-title')} area={'title'} />
                    <Heading level={3} title={t('confirm.message-sub-title')} area={'subtitle'} />
                    <TextBox area={'paragraph_1'} margin={0}>{t('confirm.message-para-1')}</TextBox>
                    <TextBox area={'paragraph_2'} margin={0}>{t('confirm.message-para-2')}</TextBox>
                    <TextBox area={'paragraph_3'} margin={0} strong={mobile}>{t('confirm.message-para-3')}</TextBox>
                  </Fragment>
                )}
                {isPayLater && (
                  <Fragment>
                    <Heading level={2} title={'Payment request sent via email'} area={'title'} />
                    <Heading level={3} title={'An email will be sent to the email address you submitted containing details of your booking and a link to pay for the booking.'} area={'subtitle'} />
                    <TextBox area={'paragraph_1'} margin={0}>{'If the confirmation does not match your booking, please contact customer service and provide your booking number.'}</TextBox>
                    <TextBox area={'paragraph_2'} margin={0} strong={mobile}>{t('confirm.message-para-3')}</TextBox>
                  </Fragment>
                )}
                <Row area={'info'} spread={false} flexWrap={'wrap'} crossAxis={'flex-start'}>
                  <InfoBlock
                    label={t('confirm.block-1-label')}
                    icon={'search'}
                    value={order.id}
                    width={'15rem'}
                    margin={'0 0 1rem 0'}
                  />
                  {!isPayLater && (
                    <InfoBlock
                      label={t('confirm.block-2-label')}
                      icon={'payment'}
                      value={[
                        t('confirm.block-2-value'),
                        `${formatCurrency(order.pricing.total, { currencyCode: order.currency })}`,
                      ]}
                      width={'15rem'}
                      margin={'0 0 1rem 0'}
                    />
                  )}
                  <InfoBlock
                    label={t('confirm.block-3-label')}
                    icon={'contact'}
                    value={[
                      t('confirm.block-3-value-1'),
                      `${order.customer.firstname} ${order.customer.lastname}`,
                      t('confirm.block-3-value-2'),
                      `${order.customer.email}`,
                      t('confirm.block-3-value-3'),
                      `${order.customer.telephone}`,
                    ]}
                    width={'15rem'}
                    margin={'0 0 1rem 0'}
                  />
                  {!isPayLater && (
                    <InfoBlock
                      label={t('confirm.block-4-label')}
                      icon={'email'}
                      value={[
                        t('confirm.block-4-value'),
                        `${order.customer.email}`,
                      ]}
                      width={'15rem'}
                      margin={'0 0 1rem 0'}
                    />
                  )}
                </Row>

                {mobile && (
                  <Summary title area={'basket'} basket={order} background={'background_ui'} />
                )}
                {app.showConfirmLinks && (
                  <Fragment>
                    {!user.authenticated && app.showLogin && (
                      <ActionCard
                        area={'login'}
                        cta
                        label={t('confirm.action-login')}
                        change={() => { }}
                        anchor={t('urls.external-login')}
                      />
                    )}
                    <ActionCard
                      area={'home'}
                      label={t('confirm.action-home')}
                      change={() => {}}
                      anchor={t('urls.external-homepage')}
                    />
                  </Fragment>
                )}
              </Area>
            </View>
            {!mobile && (
              <Summary title area={'basket'} basket={order} />
            )}
          </Layout>
        )
      }}
    </MediaQuery>
  )
}

const mobileLayout = [
  'nav',
  'form',
]

const desktopLayout = [
  'nav basket',
  'form basket',
]

const Layout = ({
  mobile,
  children,
}) => {
  return (
    <Area
      areas={mobile ? mobileLayout : desktopLayout}
      columns={mobile ? 1 : ['1fr', '20rem']}
      rows={mobile ? ['6.25rem', '1fr'] : ['5rem', '1fr']}
      colgap={0}
      rowgap={0}
      height={'var(--app-viewport)'}
      fullFlex
    >
      {children}
    </Area>
  )
}

export default Confirmation
