import React, { useLayoutEffect, useMemo } from 'react'

import isMatch from 'lodash/isMatch'
import sortBy from 'lodash/sortBy'

import { useHistory } from 'react-router-dom'

import StepLayout from '_/templates/StepLayout'
import Summary from '_/templates/Summary'
import LoadIndicator from '_/templates/LoadIndicator'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import EditorModal from '_/templates/EditorModal'

import Form from './Form'

import useForm from '_/hooks/useForm'

import {
  getSchema,
  valid_spec,
  reset_values,
} from './context'

import useOverview from '_/hooks/useOverview'
import useQuery from '_/hooks/useQuery'

import {
  parseTicketsFromQuery,
  appendTicketsToURL,
} from '_/libs/ticketDetailsURLUtils'

import {
  filterTravellers,
  filterNonTravellers,
} from '_/libs/dependantData'

import {
  stampToDateISO,
} from '_/libs/date'

import useBookings from '_/hooks/useBookings'

import useBasket from '_/hooks/useBasket'
import useCart from '_/hooks/useCart'
import { usePageView } from '_/hooks/usePageView'

import layouts from './layouts'

import getInitial from './getInitial'

import checkMultiUI from '_/libs/CheckMultiUI'

import makeSerialID from '_/libs/makeSerialID'

const enhancedValidity = ({
  meta,
  layout,
  current,
  valid,
}) => {
  const enhanced = {
    travel: (() => {
      const non_travellers = filterNonTravellers(meta, current.ticket, layout)

      return non_travellers.some(ticket => ticket.qty > 0)
    })(),
    travellers_group: (() => {
      return filterTravellers(meta, current.ticket, layout)
        .some(ticket => ticket.qty > 0)
    })(),
  }

  return {
    ...valid,
    ...enhanced,
  }
}

const checkTicketValuesMatch = (existing, changed) => {
  const isSameLength = changed.length === existing.length
  const hasSameIds = sortBy(changed, ['id']).map(({ id }) => id).join(',') === sortBy(existing, ['id']).map(({ id }) => id).join(',')

  if (isSameLength && hasSameIds) {
    return isMatch(sortBy(existing, ['id']), sortBy(changed, ['id']))
  } else {
    return false
  }
}

/*
  Alter cart items if they are no longer valid or have tickets changed
*/
const setBasketState = ({
  current,
  update,
  options,
  basket,
  cart,
}) => {
  if (!basket) return

  const {
    outbound_time,
    outbound_date,
    inbound_time,
  } = options

  const {
    outbound,
    inbound,
  } = basket.state.items

  const {
    outbound_date: initial_date,
  } = current

  if (!outbound_time.isLoaded || !outbound_date.isLoaded) return

  // Reset the outbound date and selection if it is invalid
  if (initial_date !== null && outbound === null) {
    const initialDateValid = outbound_date.data.options
      .map(({ start_date }) => start_date).includes(initial_date)

    if (!initialDateValid) {
      update([
        {
          field: 'outbound_date',
          value: null,
        },
        {
          field: 'outbound_date_selection',
          value: stampToDateISO(new Date()),
        },
      ])
    }
  }

  if (outbound !== null) {
    const outboundValid = outbound_time.data.options
      .map(makeSerialID).includes(outbound.pick_id)

    const inboundValid = inbound !== null && inbound_time.data.options
      .map(makeSerialID).includes(inbound.pick_id)

    // If outbound is wrong then inbound is as well
    // Clear them both
    if (!outboundValid) {
      basket.emptyBasket()
      cart.reset()

      update([
        {
          field: 'outbound_date',
          value: null,
        },
        {
          field: 'outbound_time',
          value: null,
        },
        {
          field: 'inbound_date',
          value: null,
        },
        {
          field: 'inbound_time',
          value: null,
        },
      ])
    } else {
      // Get the filter tickets list
      const currentTickets = current.ticket
        .filter(ticket => ticket.qty > 0)

      // Check if our tickets match
      const ticketsMatch = checkTicketValuesMatch(outbound.ticket, currentTickets)

      if (!ticketsMatch) { // No match, so update outbound
        const payload = {
          ...outbound,
          ticket: currentTickets,
        }

        basket.editBasket(payload, 'outbound')

        if (inboundValid) { // Inbound journey is still valid, update accordingly
          const payload = {
            ...inbound,
            ticket: currentTickets,
          }

          basket.editBasket(payload, 'inbound')
        } else { // Inbound journey is invalid, remove
          basket.removeFromBasket('inbound')

          update([
            {
              field: 'inbound_date',
              value: null,
            },
            {
              field: 'inbound_time',
              value: null,
            },
          ])
        }
      }
    }
  }
}

const Ticket = ({
  match,
  meta,
  initial,
  initialDate,
  layout,
}) => {
  const history = useHistory()
  const queries = useQuery()
  const basket = useBasket()
  const cart = useCart(basket.state)

  const setParams = (record) => {
    const newSearch = appendTicketsToURL({
      ...record,
      ticket: record.ticket.filter(ticket => ticket.qty > 0), // Only persist in URL if tickets have a qty
    })

    const currentSearch = window.location.search.replace('?', '')

    if (newSearch !== currentSearch) {
      history.replace({
        search: newSearch,
      })
    }
  }

  const {
    current,
    valid,
    update,
    reset,
  } = useForm(getSchema, valid_spec, initial, {
    unique: {},
    onUpdate: setParams,
  })

  usePageView('TICKET_SELECTION', () => {
    const product = meta.products.find(({ id }) => id === current.product_id)

    return {
      type: current.type,
      product: current.product_id,
      from: current.location || (product && product.locations[0]),
      to: current.end_location,
      outbound: current.outbound_date,
      return: current.inbound_date,
      ticket: current.ticket
        .filter(({ qty }) => qty > 0)
        .map(({ id, qty }) => ({ id, qty })),
    }
  })

  // Reset the form only if params contains reset=true, only used from ETE
  useLayoutEffect(() => {
    if (!queries.reset) return

    const initial = getInitial(match.params.type, meta, { ...parseTicketsFromQuery(queries), ...match.params }, layout)

    reset(initial)
  }, [queries.reset])

  const options = useBookings({ current, layout })

  // Do something here if current basket options do not sync with ticket selection
  useLayoutEffect(() => {
    setBasketState({
      current,
      update,
      options,
      basket,
      cart,
    })
  }, [current, options])

  const handleUpdate = (payload, reset = []) => {
    update([
      ...payload,
      ...reset
        .map(field => ({
          field,
          value: reset_values[field] ? reset_values[field](current[field]) : null,
        })),
    ])
  }

  const sendCart = async () => {
    const isMulti = checkMultiUI(current, layout)

    history.push(isMulti ? '/journey' : '/details')
  }

  const modal_props = {
    data: meta,
    layout,
    current,
    initialDate,
    options,
    update: handleUpdate,
  }

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <EditorModal
            mobile={mobile}
            modalProps={modal_props}
            render={mount => {
              return (
                <StepLayout mobile={mobile}>
                  <Form
                    current={current}
                    valid={enhancedValidity({
                      meta,
                      layout,
                      current,
                      valid,
                    })}
                    mobile={mobile}
                    match={match}
                    mount={mount}
                    data={meta}
                    layout={layout}
                    submit={sendCart}
                    loading={cart.isFetching}
                  />
                  {!mobile && (
                    <Summary
                      area={'basket'}
                      title
                    />
                  )}
                </StepLayout>
              )
            }}
          />
        )
      }}
    </MediaQuery>
  )
}

const Loader = ({
  match,
}) => {
  const {
    explore,
  } = window.TICKNOVATE_CONFIG

  const queries = useQuery()
  const meta = useOverview()

  const layout = useMemo(() => {
    if (explore?.[match.params.type]) {
      const {
        selector,
      } = explore[match.params.type]

      return {
        ...layouts[match.params.type][selector],
        ...explore[match.params.type],
      }
    }

    return {
      selector: 'derived',
      ...layouts.route.derived,
    }
  }, [meta.isLoaded])

  const initial = useMemo(() => {
    return getInitial(match.params.type, meta, { ...parseTicketsFromQuery(queries), ...match.params }, layout)
  }, [meta.isLoaded])

  const initialDate = useMemo(() => {
    return initial.product_id ? meta.product_info_map[initial.product_id]?.next_service_date : null
  }, [meta.isLoaded])

  if (!meta.isLoaded) {
    return (
      <MediaQuery media={media.mobile}>
        {mobile => {
          return (
            <StepLayout mobile={mobile}>
              <LoadIndicator />
            </StepLayout>
          )
        }}
      </MediaQuery>
    )
  }

  return (
    <Ticket
      match={match}
      meta={meta}
      initial={initial}
      initialDate={initialDate}
      layout={layout}
    />
  )
}

export default Loader
