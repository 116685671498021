import React from 'react'

import { useTranslation } from 'react-i18next'

import CardButton from '_/components/element/CardButton'
import Row from '_/components/layout/Row'

import InfoBlock from '_/components/layout/InfoBlock'

const ActionLocation = ({
  from,
  to,
  change,
  cta = false,
  warning = false,
  disabled = false,
  ...style
}) => {
  const {
    t,
  } = useTranslation()

  let title_color = 'card_default_title'
  let text_color = 'card_default_text'
  let icon_color = 'card_default_icon'

  if (cta) {
    icon_color = 'card_cta_icon'
    title_color = 'card_cta_title'
    text_color = 'text_light'
  }

  if (warning) {
    icon_color = 'text_error'
    title_color = 'card_warning_title'
    text_color = 'text_error'
  }

  if (disabled) {
    icon_color = 'card_disabled_icon'
    title_color = 'card_disabled_title'
    text_color = 'text_disabled'
  }

  return (
    <CardButton change={change} minHeight={'3.125rem'} padding={'0.5rem 0.5rem 1rem 0.5rem'} cta={cta} disabled={disabled} {...style} warning={warning}>
      <Row type={'spaced'}>
        <InfoBlock
          icon={'location'}
          label={t('ticket.label-depart')}
          titleColor={title_color}
          textColor={text_color}
          fill={icon_color}
          value={from}
        />
        <InfoBlock
          icon={'location'}
          label={t('ticket.label-arrive')}
          titleColor={title_color}
          textColor={text_color}
          fill={icon_color}
          value={to}
        />
      </Row>
    </CardButton>
  )
}

ActionLocation.styleable = true

export default ActionLocation
