
import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from '../../../../../libs/moneyFormatting'
import { paymentMethods } from '../../../../../libs/constants'

import styles from '../../../../organisms/Form-Billing/formbilling.scss'

import Container from '../../../../containers/Container'
import TextBox from '../../../../containers/TextBox'
import Heading from '../../../../atoms/Heading'
import SectionLabel from '../../../../molecules/SectionLabel'
import Notification from '../../../../molecules/Notification'
import { Pane, StatefulTabNav } from '../../../../molecules/TabNav'

import SagePayForm from './SagePayForm'
import GenericFreePaymentForm from './GenericFreePaymentForm'
import RefundForm from './RefundForm'
import useAllowedPaymentMethods from '../../../../hooks/useAllowedPaymentMethods'
import NetsPaymentForm from './NetsPaymentForm'
import { getCombinedAsyncStatus } from '../../../../hooks/useAsyncData'
import useLoadPaymentScripts from '../../../../hooks/useLoadPaymentScripts'
import WorldpayOnlinePaymentForm from './WorldpayOnlinePaymentForm'
import PayLaterPaymentForm from './PayLaterPaymentForm'

const PaymentSection = (props) => {
  const {
    draftCart,
    redirectToConfirmation,
    tradePartner,
    termsAndConditions,
    billingAddress,
  } = props

  const {
    app,
    env,
  } = window.TICKNOVATE_CONFIG

  // Only show pay by email in the payment
  const payLaterEnabled = app.enablePayLater && env.app === 'reservations' && !env.embeddedMode

  const { enabledPaymentMethods } = window.TICKNOVATE_CONFIG.payment
  const allowedPaymentMethodsReq = useAllowedPaymentMethods(draftCart)
  const allowedPaymentMethods = (allowedPaymentMethodsReq.data || [])
    .filter(
      ({ method }) => enabledPaymentMethods.includes(method),
    )
  const paymentMethodsLoading = useLoadPaymentScripts(enabledPaymentMethods)

  const combinedAsyncStatus = getCombinedAsyncStatus([
    allowedPaymentMethodsReq,
    paymentMethodsLoading,
  ])

  const { t } = useTranslation()

  const availablePaymentMethods =
    draftCart.pricing.payable === 0
      ? [
        {
          id: paymentMethods.NONE,
          label: t(
            'billing.sections.payment.label-payment-none',
            'Pre-Payment',
          ),
          disabled: false,
          render: () => (
            <GenericFreePaymentForm
              {...props}
              paymentMethod={paymentMethods.NONE}
            />
          ),
        },
      ]
      : draftCart.pricing.payable < 0
        ? [
          {
            id: paymentMethods.REFUND,
            label: t('billing.sections.payment.label-payment-refund', 'Refund'),
            disabled: false,
            render: () => <RefundForm {...props} />,
          },
        ]
        : [
          allowedPaymentMethods.some(
            (obj) => obj.method === paymentMethods.CARD,
          ) && {
            id: paymentMethods.CARD,
            label: t('billing.sections.payment.label-payment-card', 'Card'),
            disabled: false,
            render: () => (
              <fieldset style={{ borderWidth: 0 }}>
                <legend>
                  <Container styling={styles.paddingTop}>
                    <SectionLabel
                      title={t(
                        'billing.sections.payment.title-card-payment',
                        'Card payment details',
                      )}
                    />
                  </Container>
                </legend>
                <Container className={styles.billInfo}>
                  <Heading
                    title={t('billing.sections.payment.title-payment-amount', {
                      payable: formatCurrency(draftCart.pricing.payable, { currencyCode: draftCart.currency }),
                      defaultValue: `Total payment amount: ${formatCurrency(
                        draftCart.pricing.payable,
                        { currencyCode: draftCart.currency },
                      )}`,
                    })}
                    level={2}
                  />
                </Container>
                {window.TICKNOVATE_CONFIG.checkout.showFullPaymentRequiredText && (
                  <Notification bold>
                    {t(
                      'billing.sections.payment.warning-full-payment',
                      'Customer must make full payment to secure the booking',
                    )}
                  </Notification>
                )}
                <SagePayForm
                  draftCart={draftCart}
                  redirectToConfirmation={redirectToConfirmation}
                  termsAndConditions={termsAndConditions}
                  billingAddress={billingAddress}
                />
              </fieldset>
            ),
          },
          allowedPaymentMethods.some((obj) => obj.method === 'nets_easy') && {
            id: paymentMethods.NETS_EASY,
            label: t('billing.sections.payment.label-payment-card', 'Card'),
            disabled: false,
            render () {
              return (
                <NetsPaymentForm
                  cart={draftCart}
                  redirectToConfirmation={redirectToConfirmation}
                  termsAndConditions={termsAndConditions}
                  config={allowedPaymentMethods.find(
                    ({ method }) => method === 'nets_easy',
                  )}
                />
              )
            },
          },
          allowedPaymentMethods.some(
            (obj) => obj.method === paymentMethods.WORLDPAY_ONLINE,
          ) && {
            id: paymentMethods.WORLDPAY_ONLINE,
            label: 'Pay with Worldpay',
            disabled: false,
            render () {
              return (
                <WorldpayOnlinePaymentForm
                  cart={draftCart}
                  redirectToConfirmation={redirectToConfirmation}
                  termsAndConditions={termsAndConditions}
                  config={allowedPaymentMethods.find(
                    ({ method }) => method === 'worldpay_online',
                  )}
                />
              )
            },
          },
          payLaterEnabled && {
            id: paymentMethods.PAY_LATER,
            label: t('billing.sections.pay-later.title', 'Pay via email'),
            disabled: false,
            render() {
              return (
                <PayLaterPaymentForm 
                  cart={draftCart}
                  redirectToConfirmation={redirectToConfirmation}
                  termsAndConditions={termsAndConditions}
                />
              )
            },
          },
          tradePartner && {
            id: paymentMethods.CREDIT,
            label: t(
              'billing.sections.payment.label-payment-invoice',
              'Invoice',
            ),
            disabled: !allowedPaymentMethods.some(
              (obj) => obj.method === paymentMethods.CREDIT,
            ),
            render () {
              return (
                <fieldset>
                  <legend>
                    <Container styling={styles.paddingTop}>
                      <TextBox noMargin styling={styles.importantText}>
                        {t(
                          'billing.sections.payment.warning-invoice',
                          'Booking payment handled externally from the system with an invoice sent by the accounts team.',
                        )}
                      </TextBox>
                    </Container>
                  </legend>
                  <GenericFreePaymentForm
                    {...props}
                    paymentMethod={paymentMethods.CREDIT}
                    redirectToConfirmation={redirectToConfirmation}
                  />
                </fieldset>
              )
            },
          },
        ].filter(Boolean)

  if (combinedAsyncStatus.NotReadyComponent) {
    return <combinedAsyncStatus.NotReadyComponent />
  }

  if (availablePaymentMethods.length === 1) {
    return availablePaymentMethods[0].render()
  }

  return (
    <StatefulTabNav>
      {availablePaymentMethods.map(({ id, label, render, disabled }) => (
        <Pane key={id} id={id} label={<span>{label}</span>} disabled={disabled}>
          {render()}
        </Pane>
      ))}
    </StatefulTabNav>
  )
}

export default PaymentSection
