import React, { Fragment } from 'react'

import { useTranslation } from 'react-i18next'

import { ResponsiveView as View } from '_/components/layout/View'

import Area from '_/components/layout/Area'
import NavFlow from '_/components/navigation/NavFlow'

import Heading from '_/components/layout/Heading'
import IconTitle from '_/templates/IconTitle'

import ActionSelector from '_/components/action/ActionSelector'
import ActionBasket from '_/components/action/ActionBasket'
import ActionCard from '_/components/action/ActionCard'

import DescriptionBlock from '_/components/layout/DescriptionBlock'

import FooterWrapper from '_/components/layout/FooterWrapper'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import ActionLocation from '_/components/action/ActionLocation'

import {
  filterTravellers,
  filterNonTravellers,
  getTravellerValue,
  getMetaFromProduct,
} from '_/libs/dependantData'

import useBasket from '_/hooks/useBasket'

import { formatUTC } from '_/libs/dateFormatter'

import checkMultiUI from '_/libs/CheckMultiUI'

const getTime = time => {
  const [
    hour,
    minute,
  ] = time.split(':')

  return `${hour}:${minute}`
}

const getProductTitle = (meta, id, time) => {
  const info = meta.product_info_map[id]

  return info.booking_unit.type === 'time' && time !== null ? `${info.title} ${getTime(time)}` : info.title
}

const getTicketTitle = (meta, item) => {
  if (!item) return null

  const title = getProductTitle(meta, item.product_id, item.start_time)

  return item.combo_id !== null ? `${meta.combo_map[item.combo_id]}, ${title}` : title
}

const fields = {
  info: ({
    current,
    data,
  }) => {
    const meta_value = getMetaFromProduct(current.product_id, data)

    return (
      <DescriptionBlock
        area={'info'}
        label={meta_value.title}
        value={meta_value.description}
      />
    )
  },
  heading_how: ({
    t,
  }) => {
    return (
      <Heading level={2} title={t('ticket.title-how')} area={'heading_how'} />
    )
  },
  heading_route: ({
    t,
  }) => {
    return (
      <Heading level={2} title={t('ticket.title-route')} area={'heading_route'} />
    )
  },
  heading_outbound: ({
    t,
  }) => {
    return (
      <IconTitle icon={'arrow_right'} title={t('ticket.title-outbound')} area={'heading_outbound'} />
    )
  },
  heading_return: ({
    t,
  }) => {
    return (
      <IconTitle icon={'arrow_left'} title={t('ticket.title-inbound')} area={'heading_return'} />
    )
  },
  location: ({
    t,
    mount,
    valid,
    current,
    data,
    layout,
    colgap,
  }) => {
    if (layout.locationSplit) {
      return (
        <Area
          area={'location'}
          areas={[
            'from to',
          ]}
          columns={2}
          colgap={colgap}
        >
          <ActionSelector
            area={'from'}
            icon={'location'}
            label={t('ticket.label-depart')}
            warning={!valid.location}
            value={current.location ? data.location_map[current.location] : null}
            change={() => mount('location')}
          />
          <ActionSelector
            area={'to'}
            icon={'location'}
            label={t('ticket.label-arrive')}
            warning={!valid.end_location}
            value={current.end_location ? data.location_map[current.end_location] : null}
            change={() => mount('location', 1)}
            disabled={!valid.location}
          />
        </Area>
      )
    }

    return (
      <ActionLocation
        area={'location'}
        change={() => mount('location')}
        warning={!valid.location || !valid.end_location}
        from={current.location ? data.location_map[current.location] : null}
        to={current.end_location ? data.location_map[current.end_location] : null}
      />
    )
  },
  travel: ({ // Only used for group mode
    t,
    mount,
    valid,
    current,
    data,
    layout,
  }) => {
    const non_travellers = filterNonTravellers(data, current.ticket, layout)

    const travel_value = non_travellers
      .filter(ticket => ticket.qty === 1)

    return (
      <ActionSelector
        area={'travel'}
        icon={'travel'}
        label={t('ticket.label-travel')}
        warning={!valid.travel}
        value={valid.travel ? data.tickets_map[travel_value[0].id].long : null}
        change={() => mount('travel')}
        disabled={current.type === 'route' && (!valid.location || !valid.end_location)}
      />
    )
  },
  travel_simple: ({ // Only used for group mode
    t,
    mount,
    valid,
    current,
    data,
    layout,
  }) => {
    const non_travellers = filterNonTravellers(data, current.ticket, layout)

    const travel_value = non_travellers
      .filter(ticket => ticket.qty === 1)

    return (
      <ActionSelector
        area={'travel_simple'}
        icon={'travel'}
        label={t('ticket.label-travel')}
        warning={!valid.travel}
        value={valid.travel ? data.tickets_map[travel_value[0].id].long : null}
        change={() => mount('travelSimple')}
        disabled={current.type === 'route' && (!valid.location || !valid.end_location)}
      />
    )
  },
  travellers: ({ // Only runs when not in group mode
    t,
    mount,
    valid,
    current,
    data,
  }) => {
    const value = current.ticket
      .filter(ticket => ticket.qty > 0)
      .map(ticket => {
        const info = data.tickets.find(item => item.value === ticket.id)

        return {
          ...ticket,
          label: info ? info.label : 'undefined',
        }
      })
      .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
      .map(ticket => {
        return `${ticket.qty} x ${ticket.label}`
      })

    return (
      <ActionSelector
        area={'travellers'}
        icon={'passenger'}
        label={t('ticket.label-travellers')}
        warning={!valid.ticket}
        value={value.length > 0 ? value : null}
        change={() => mount('travellers')}
      />
    )
  },
  travellers_group: ({ // Only runs in group mode
    t,
    mount,
    valid,
    current,
    data,
    layout,
  }) => {
    const travellers = filterTravellers(data, current.ticket, layout)
      .filter(({ qty }) => qty > 0)

    return (
      <ActionSelector
        area={'travellers_group'}
        icon={'passenger'}
        label={t('ticket.label-travellers')}
        warning={!valid.travellers_group}
        value={travellers.length > 0 ? getTravellerValue(data, current.ticket, layout) : null}
        change={() => mount('travellers')}
        disabled={current.type === 'route' && (!valid.location || !valid.end_location)}
      />
    )
  },
  outbound: ({
    t,
    mount,
    valid,
    current,
    data,
    basket,
    layout,
  }) => {
    const {
      travel,
      travellers_group,
      ticket,
    } = valid

    const travellers_valid = layout.selector === 'group' ? travel && travellers_group : ticket

    return (
      <Area
        area={'outbound'}
        areas={[
          'date',
          'ticket',
        ]}
        columns={1}
        rowgap={0.25}
      >
        <ActionSelector
          area={'date'}
          icon={'calendar'}
          label={t('ticket.label-date')}
          warning={!valid.outbound_date}
          disabled={!valid.location || !valid.end_location || !travellers_valid}
          change={() => mount('ticketSelectionReturn')}
          value={current.outbound_date !== null ? formatUTC(current.outbound_date, 'D MMMM YYYY') : null}
          withBorder={false}
        />
        <ActionSelector
          area={'ticket'}
          icon={'ticket'}
          label={t('ticket.label-ticket')}
          warning={valid.outbound_date && !basket.items.outbound}
          disabled={!valid.outbound_date}
          change={() => mount('ticketSelectionReturn', 1)}
          value={getTicketTitle(data, basket.items.outbound)}
        />
      </Area>
    )
  },
  single: ({
    t,
    mount,
    valid,
    current,
    data,
    basket,
    layout,
  }) => {
    const {
      travel,
      travellers_group,
      ticket,
    } = valid

    const travellers_valid = layout.selector === 'group' ? travel && travellers_group : ticket

    return (
      <Area
        area={'single'}
        areas={[
          'date',
          'ticket',
        ]}
        columns={1}
        rowgap={0.25}
      >
        <ActionSelector
          area={'date'}
          icon={'calendar'}
          label={t('ticket.label-date')}
          warning={!valid.outbound_date}
          disabled={!travellers_valid}
          change={() => mount('ticketSelectionReturn')}
          value={current.outbound_date !== null ? formatUTC(current.outbound_date, 'D MMMM YYYY') : null}
          withBorder={false}
        />
        <ActionSelector
          area={'ticket'}
          icon={'ticket'}
          label={t('ticket.label-ticket')}
          warning={valid.outbound_date && !basket.items.outbound}
          disabled={!valid.outbound_date}
          change={() => mount('ticketSelectionReturn', 1)}
          value={getTicketTitle(data, basket.items.outbound)}
        />
      </Area>
    )
  },
  return: ({
    t,
    mount,
    valid,
    current,
    data,
    basket,
    layout,
  }) => {
    const {
      travel,
      travellers_group,
      ticket,
    } = valid

    const travellers_valid = layout.selector === 'group' ? travel && travellers_group : ticket

    let restrict = false

    if (layout.restrictReturn && layout.restrictReturn.includes(current.location)) {
      restrict = true
    }

    if ((current.inbound_date || basket.items.inbound) && travellers_valid) {
      return (
        <Area
          area={'return'}
          areas={[
            'date',
            'ticket',
          ]}
          columns={1}
          rowgap={0.25}
        >
          <ActionSelector
            area={'date'}
            icon={'calendar'}
            label={t('ticket.label-date')}
            warning={!valid.inbound_date}
            disabled={restrict || !valid.location || !valid.end_location || !travellers_valid}
            change={() => mount('ticketSelectionReturn', 2)}
            value={current.inbound_date !== null ? formatUTC(current.inbound_date, 'D MMMM YYYY') : null}
            withBorder={false}
          />
          <ActionSelector
            area={'ticket'}
            icon={'ticket'}
            label={t('ticket.label-ticket')}
            warning={valid.inbound_date && !basket.items.inbound}
            disabled={!valid.inbound_date}
            change={() => mount('ticketSelectionReturn', 3)}
            value={getTicketTitle(data, basket.items.inbound)}
          />
        </Area>
      )
    }

    return (
      <ActionSelector
        area={'return'}
        icon={'calendar'}
        label={t('ticket.label-return')}
        rawStyle={{ alignSelf: 'auto' }}
        change={() => mount('ticketSelectionReturn', 2)}
        disabled={restrict || !valid.outbound_date || !basket.items.outbound}
        offer
      />
    )
  },
  time: ({
    t,
    mount,
    valid,
    current,
    data,
    basket,
    layout,
  }) => {
    const {
      travel,
      travellers_group,
      ticket,
    } = valid

    const travellers_valid = layout.selector === 'group' ? travel && travellers_group : ticket

    return (
      <Area
        area={'time'}
        areas={[
          'date',
          'ticket',
        ]}
        columns={1}
        rowgap={0.25}
      >
        <ActionSelector
          area={'date'}
          icon={'calendar'}
          label={t('ticket.label-date')}
          warning={!valid.outbound_date}
          disabled={!travellers_valid}
          change={() => mount('ticketSelectionTime')}
          value={current.outbound_date !== null ? formatUTC(current.outbound_date, 'D MMMM YYYY') : null}
          withBorder={false}
        />
        <ActionSelector
          area={'ticket'}
          icon={'time'}
          label={t('ticket.label-ticket')}
          warning={valid.outbound_date && !basket.items.outbound}
          disabled={!valid.outbound_date}
          change={() => mount('ticketSelectionTime', 1)}
          value={getTicketTitle(data, basket.items.outbound)}
        />
      </Area>
    )
  },
  submit: ({
    t,
    submit,
    basket,
    loading,
  }) => {
    return (
      <ActionCard
        disabled={basket.items.outbound === null || loading}
        label={t('meta.done')}
        change={submit}
        area={'submit'}
        margin={'0 0 6rem 0'}
        loading={loading}
        cta
      />
    )
  },
}

const Form = ({
  current,
  valid,
  mobile,
  match,
  mount,
  data,
  layout,
  submit,
  loading,
}) => {
  const { t } = useTranslation()

  const {
    state: basket,
  } = useBasket()

  const {
    app,
  } = window.TICKNOVATE_CONFIG

  const isMulti = checkMultiUI(current, layout)

  return (
    <Fragment>
      <NavFlow
        mobile={mobile}
        match={match}
        area={'nav'}
        mount={mount}
        flow={isMulti ? 'journey' : null}
      >
        {app.basketInMenu && (
          <ActionBasket
            type={'menu'}
            change={() => mount('basket')}
            area={'child'}
            gridAlign={'stretch'}
          />
        )}
      </NavFlow>
      <View mobile={mobile} area={'form'} gridAlign={'stretch'}>
        <MediaQuery media={media.tablet}>
          {tablet => {
            const options = layout[tablet ? 'mobile' : 'desktop']
              .reduce((acc, cur) => {
                cur
                  .split(' ')
                  .filter(field => field !== '.')
                  .map(field => field.replace(/\/[0-9]+/, ''))
                  .forEach(field => acc.push(field))

                return acc
              }, [])

            const colgap = tablet ? 0.5 : 1.5

            return (
              <Area
                areas={layout[tablet ? 'mobile' : 'desktop']}
                columns={tablet ? 2 : 4}
                colgap={colgap}
                rowgap={tablet ? 1 : 1.5}
              >
                {options.map(option => {
                  const Module = fields[option]

                  return (
                    <Module
                      key={option}
                      {...{
                        t,
                        mount,
                        valid,
                        current,
                        data,
                        submit,
                        basket,
                        layout,
                        loading,
                        colgap,
                      }}
                    />
                  )
                })}
              </Area>
            )
          }}
        </MediaQuery>
        {mobile && !app.basketInMenu && (
          <FooterWrapper>
            <ActionBasket
              change={() => mount('basket')}
            />
          </FooterWrapper>
        )}
      </View>
    </Fragment>
  )
}

export default Form
