import React from 'react'
import styles from './button.scss'

import StyleWrapper from '@ticknovate/frontend-shared/style/StyleWrapper'

const RoundedButton = ({
  change,
  children,
  ...style
}) => {
  return (
    <StyleWrapper
      {...style}
      render={styling => {
        return (
          <button
            style={styling}
            className={styles.button}
            onClick={change}
          >
            {children}
          </button>
        )
      }}
    />
  )
}

export default RoundedButton
