import React, { Fragment, useState } from 'react'

import { useTranslation } from 'react-i18next'

import sortBy from 'lodash/sortBy'

import ModalView from '_/components/layout/ModalView'
import ModalColumn from '_/components/layout/ModalColumn'
import Area from '_/components/layout/Area'
import Heading from '_/components/layout/Heading'

import OptionListHorizontal from '_/components/element/OptionListHorizontal'
import OptionList from '_/components/element/OptionList'

import StepRouter from '_/components/navigation/StepRouter'

import useRevert from '_/hooks/useRevert'

import {
  filterNonTravellers,
  filterTicketsFromProducts,
} from '_/libs/dependantData'

import checkMultiUI from '_/libs/CheckMultiUI'

const getCurrentGroupId = (group, data) => {
  const found = data.find(item => item.entity_id === group)

  return found ? found.external_id : null
}

const getInitialGroup = (meta, ticket, layout) => {
  const non_travellers = filterNonTravellers(meta, ticket, layout)
    .filter(ticket => ticket.qty === 1)

  return non_travellers.length === 1 ? meta.tickets_group_map[non_travellers[0].id].entity_id : null
}

/*
  "disableWalk": true,
    "walkID": "PA"
*/
const Travel = ({
  data,
  current,
  update,
  close,
  layout,
}) => {
  const { t } = useTranslation()

  const [
    group,
    setGroup,
  ] = useState(getInitialGroup(data, current.ticket, layout))

  const revert = useRevert(current, update, true)

  const isMulti = checkMultiUI(current, layout)

  const {
    explore,
  } = window.TICKNOVATE_CONFIG

  let ticket_options = filterTicketsFromProducts(current, data)

  const group_options = ticket_options
    .reduce((list, ticket_type) => {
      const group = data.tickets_group_map[ticket_type.value].entity_id

      if (!list.includes(group)) list.push(group)

      return list
    }, [])
    .map(group => {
      const record = data.groups.find(item => item.entity_id === group)

      return {
        ...record,
      }
    })

  if (explore.disableWalk) {
    const walk_index = group_options.findIndex(option => option.external_id === explore.walkID)

    if (walk_index !== -1) {
      const walk_group = group_options.splice(walk_index, 1)

      if (!isMulti) group_options.push(walk_group[0])
    }
  }

  if (group) {
    ticket_options = sortBy(
      ticket_options
        .filter(ticket => ticket.group === group),
      ['order'],
    )
  }

  const handleGroupChange = (value) => {
    const ticket_reset = current.ticket
      .map(({ id, qty }) => {
        return {
          id,
          qty: data.tickets_group_map[id].id !== explore[current.type].travellerGroup ? 0 : qty,
        }
      })

    update(
      [
        {
          field: 'ticket',
          value: ticket_reset,
        },
      ],
    )

    setGroup(value)
  }

  const handleTicketChange = (value) => {
    const non_travellers = filterNonTravellers(data, current.ticket, layout)
      .map(({ id }) => id)

    const ticket_reset = current.ticket
      .map(({ id, qty }) => {
        return {
          id,
          qty: id === value ? 1 : non_travellers.includes(id) ? 0 : qty,
        }
      })

    update(
      [
        {
          field: 'ticket',
          value: ticket_reset,
        },
      ],
    )
  }

  const wrappedClose = () => {
    revert()
    close()
  }

  const current_ticket = current.ticket
    .filter(ticket => data.tickets_group_map[ticket.id].id !== explore[current.type].travellerGroup)
    .filter(ticket => ticket.qty === 1)

  const group_external = getCurrentGroupId(group, data.groups)

  const is_locked_walk = explore.disableWalk && explore.walkID === group_external

  return (
    <ModalView close={wrappedClose}>
      <StepRouter
        save={close}
        routes={[
          {
            title: t('ticket.model-title-travel'),
            icon: 'travel',
            valid: current_ticket.length > 0,
            view: ({ ...viewProps }) => (
              <ModalColumn {...viewProps}>
                <Area
                  areas={[
                    'group',
                    'info',
                    'ticket_title',
                    'ticket',
                  ]}
                  columns={1}
                  rowgap={1.5}
                >
                  <OptionListHorizontal
                    area={'list'}
                    value={group}
                    options={group_options}
                    change={handleGroupChange}
                  />
                  {group !== null && !is_locked_walk && (
                    <Fragment>
                      <Heading level={3} title={t('ticket.option-travel-type')} />
                      <OptionList
                        area={'ticket'}
                        value={current_ticket.length > 0 ? current_ticket[0].id : null}
                        options={ticket_options}
                        change={handleTicketChange}
                      />
                    </Fragment>
                  )}
                  {is_locked_walk && (
                    <Fragment>
                      <Heading level={3} title={t('ticket.warning-walk-travellers')} />
                    </Fragment>
                  )}
                </Area>
              </ModalColumn>
            ),
          },
        ]}
      />
    </ModalView>
  )
}

export default Travel
