import React from 'react'

import Row from '_/components/layout/Row'
import RoundedButton from '_/components/element/RoundedButton'
import InlineTextBox from '_/components/layout/InlineTextBox'
import Icon from '_/components/element/Icon'

const ActionRadioGroup = ({
  field,
  value: selected,
  options,
  change,
}) => {
  const {
    theme,
  } = window.TICKNOVATE_CONFIG

  const handleChange = (value) => () => change(field, value)

  return (
    <Row type={'start'}>
      {options.map(({ label, value, icon: name }) => {
        const active = value === selected

        const style = {
          background: `RGB(var(--theme_radio_${active ? 'selected' : 'default'}))`,
          border: `0.2rem solid RGB(var(--theme_radio_${active ? 'selected' : 'default'}_border))`,
          padding: '0 0.5rem',
        }

        const color = active ? 'radio_selected_text' : 'radio_default_text'

        return (
          <RoundedButton rawStyle={style} change={handleChange(value)} key={value}>
            <Icon name={name} size={34} fill={theme[color]} />
            <InlineTextBox strong color={color}>{label}</InlineTextBox>
          </RoundedButton>
        )
      })}
    </Row>
  )
}

export default ActionRadioGroup
