import React from 'react'

import styles from './sectionlabel.scss'

import Row from '../../containers/Row'

import Heading from '../../atoms/Heading'

import InlineTextBox from '../../containers/InlineTextBox'

const SectionLabel = (props) => {
  const {
    title,
    stage,
    styling,
    showLabel = false,
    ...rest
  } = props

  return (
    <Row
      type={'start'}
      styling={[styles.layout, styling].join(' ')}
      {...rest}
    >
      {showLabel && (
        <Badge
          label={stage}
        />
      )}
      <Heading
        title={title}
        level={1}
      />
    </Row>
  )
}

const Badge = (props) => {
  const {
    label,
  } = props

  return (
    <Row
      type={'center'}
      styling={styles.badge}
    >
      <svg
        width={44}
        height={88}
        viewBox={'0 0 16 32'}
      >
        <polygon
          fill={window.TICKNOVATE_CONFIG.theme.heading_section_label}
          points={'0 0 16 16 0 32'}
        />
      </svg>
      <InlineTextBox
        styling={styles.label}
      >
        {label}
      </InlineTextBox>
    </Row>
  )
}

export default SectionLabel
