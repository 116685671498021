/* eslint-disable camelcase */
import React from 'react'

import { useTranslation } from 'react-i18next'

import ModalView from '_/components/layout/ModalView'

import StepRouter from '_/components/navigation/StepRouter'

import useRevert from '_/hooks/useRevert'
import useBasket from '_/hooks/useBasket'

import Calendar, { Holder as CalendarHolder } from '_/templates/CalendarSelector'
import Ticket, { Holder as TicketHolder } from '_/templates/TicketSelector'
import TicketMulti, { Holder as TicketMultiHolder } from '_/templates/TicketMultiLegSelector'

import {
  stampToDateISO,
} from '_/libs/date'

import checkMultiUI from '_/libs/CheckMultiUI'

const TicketSelectionReturn = ({
  data,
  current,
  options,
  layout,
  update,
  close,
  initial,
}) => {
  const { t } = useTranslation()

  const revert = useRevert(current, update, true)

  const {
    state: basket_state,
  } = useBasket()

  const wrappedClose = () => {
    revert()
    close()
  }

  const isMulti = checkMultiUI(current, layout)

  const TicketHolderComponent = isMulti ? TicketMultiHolder : TicketHolder
  const TicketComponent = isMulti ? TicketMulti : Ticket

  const currentCombo = basket_state.items.outbound !== null ? basket_state.items.outbound.combo_id : null

  let hideCombos = false

  if (layout.restrictReturn && layout.restrictReturn.includes(current.location)) {
    hideCombos = true
  }

  return (
    <ModalView close={wrappedClose}>
      <StepRouter
        save={close}
        compact
        finalStep={(current.inbound_date !== null) || (currentCombo !== null) ? 3 : 1}
        initial={initial}
        routes={[
          {
            title: t('ticket.model-title-outbound-date'),
            icon: 'calendar',
            valid: current.outbound_date !== null,
            view: ({ ...viewProps }) => (
              <CalendarHolder {...viewProps}>
                <Calendar
                  field={'outbound'}
                  current={current}
                  startLimitDate={null}
                  initial={current.outbound_date_selection}
                  update={update}
                  options={options}
                />
              </CalendarHolder>
            ),
          },
          {
            title: t('ticket.model-title-outbound-ticket'),
            icon: 'ticket',
            valid: current.outbound_time !== null,
            view: ({ ...viewProps }) => (
              <TicketHolderComponent {...viewProps}>
                <TicketComponent
                  field={'outbound'}
                  data={data}
                  current={current}
                  startLimitDate={stampToDateISO(new Date())}
                  update={update}
                  options={options}
                  layout={layout}
                  hideCombos={hideCombos}
                />
              </TicketHolderComponent>
            ),
          },
          {
            title: t('ticket.model-title-inbound-date'),
            icon: 'calendar',
            valid: current.inbound_date !== null,
            view: ({ ...viewProps }) => (
              <CalendarHolder {...viewProps}>
                <Calendar
                  field={'inbound'}
                  current={current}
                  startLimitDate={current.outbound_date}
                  initial={current.outbound_date}
                  update={update}
                  options={options}
                />
              </CalendarHolder>
            ),
          },
          {
            title: t('ticket.model-title-inbound-date'),
            icon: 'ticket',
            valid: current.inbound_time !== null,
            view: ({ ...viewProps }) => (
              <TicketHolderComponent {...viewProps}>
                <TicketComponent
                  field={'inbound'}
                  data={data}
                  current={current}
                  startLimitDate={current.inbound_date}
                  update={update}
                  options={options}
                  layout={layout}
                />
              </TicketHolderComponent>
            ),
          },
        ]}
      />
    </ModalView>
  )
}

export default TicketSelectionReturn
