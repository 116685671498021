const filterTravellers = (meta, ticket, layout) => {
  if (!layout.travellerGroup) return ticket

  return ticket
    .filter(ticket => meta.tickets_group_map[ticket.id].id === layout.travellerGroup)
}

const filterNonTravellers = (meta, ticket, layout) => {
  if (!layout.travellerGroup) return ticket

  return ticket
    .filter(ticket => meta.tickets_group_map[ticket.id].id !== layout.travellerGroup)
}

const getTravellerValue = (meta, ticket, layout) => {
  return filterTravellers(meta, ticket, layout)
    .filter(ticket => ticket.qty > 0)
    .map(ticket => {
      const info = meta.tickets_map?.[ticket.id]

      return {
        ...ticket,
        label: info ? info.short : 'undefined',
      }
    })
    .sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
    .map(ticket => {
      return `${ticket.qty} ${ticket.label}`
    })
}

const getMetaFromProduct = (id, data) => {
  const {
    products,
  } = data

  const found = products.find(product => product.id === id)

  if (found) {
    return {
      title: found.title,
      description: found.description,
      ticket_types: found.ticket_types,
    }
  } else {
    return {
      title: 'undefined',
      description: 'undefined',
      ticket_types: [],
    }
  }
}

const getBookingSearch = ({
  field = 'outbound',
  data,
  dates = [],
  time,
}) => {
  const search = {
    type: data.type,
    product_id: data.product_id,
    dates,
    location: field === 'inbound' ? data.end_location : data.location,
    end_location: field === 'inbound' ? data.location : data.end_location,
    ticket: data.ticket.filter(({ qty }) => qty > 0),
    time,
    ...(data.itemId && { booking: data.itemId }),
  }

  if (field === 'inbound' && data.selection !== null) {
    const {
      instance_id,
      product_id,
      start_date,
      start_time,
    } = data.selection

    const selection = [
      {
        product: {
          id: product_id,
        },
        booked_unit: {
          instance_id,
          start_date,
          start_time,
        },
        location: {
          id: data.location,
        },
        end_location: {
          id: data.end_location,
        },
        ticket_types: data.ticket
          .filter(({ qty }) => qty > 0)
          .map(({ id, qty }) => ({
            id,
            qty,
          })),
      },
    ]

    search.selection = JSON.stringify(selection)
  }

  return search
}

const filterTicketsFromProducts = (current, meta) => {
  const {
    location,
    end_location,
  } = current

  const {
    products,
    product_info_map,
  } = meta

  let filtered_products = []

  if (current.type === 'route') {
    filtered_products = products
      .filter(product => {
        if (product.routes) {
          return product.routes.some(({ departure_location, arrival_location }) => departure_location === location && arrival_location === end_location)
        } else {
          return product.locations.includes(location) && product.locations.includes(end_location)
        }
      })
  } else {
    filtered_products = [product_info_map[current.product_id]]
  }

  const filtered_tickets = filtered_products
    .reduce((list, product) => {
      const {
        ticket_types,
      } = product

      ticket_types
        .forEach(ticket_id => {
          if (!list.includes(ticket_id)) list.push(ticket_id)
        })

      return list
    }, [])
    .map(ticket_id => {
      return {
        group: meta.tickets_group_map[ticket_id].entity_id,
        label: meta.tickets_map[ticket_id].short,
        value: ticket_id,
        order: meta.tickets_map[ticket_id].order,
      }
    })

  return filtered_tickets
}

export {
  filterTravellers,
  filterNonTravellers,
  filterTicketsFromProducts,
  getTravellerValue,
  getMetaFromProduct,
  getBookingSearch,
}
