import React, { Fragment, useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import * as cartApi from '../../../../../../api/cart'
import styles from '../../../../../organisms/Form-Billing/formbilling.scss'

import { triggerGenericToastError, triggerToastMessage } from '../../../../../molecules/Toaster'
import Container from '../../../../../containers/Container'
import ModalButton from '../../../../../atoms/ModalButton'
import Notification from '../../../../../molecules/Notification'
import useEventListener from '../../../../../hooks/useEventListener'

import SagePayIframe from './SagePayIframe'
import Iframe3DSecure from '../Iframe3DSecure'
import SagePaySessionKey from './SagePaySessionKey'

const SagePayForm = props => {
  const {
    redirectToConfirmation,
    draftCart,
    termsAndConditions,
    billingAddress,
  } = props
  const [sessionKey] = useState(() => new SagePaySessionKey())
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [is3DSecureSubmitting, setIs3DSecureSubmitting] = useState(false)
  const [dataFor3DSecureRedirect, setDataFor3DSecureRedirect] = useState(null)
  const isApiUnavailable = !window.sagepayCheckout
  const sagePayForm = useRef()
  const cartRef = useRef()

  const {
    t,
  } = useTranslation()

  const completeOrderAndRedirect = async () => {
    const order = await cartApi.placeOrder(
      draftCart,
      draftCart.suppress_emails,
    )
    redirectToConfirmation(order)
  }

  // `loadSagePayForm` creates a closure around values and can become stale.
  // Use a ref to allow it to access most recent value.
  // TODO: move `loadSagePayForm` out of this component so it is clear where its variables are coming from
  useEffect(() => {
    cartRef.current = draftCart
  }, [draftCart])

  // Listen to messages from the 3D secure iframe
  useEventListener(
    window,
    'message',
    useCallback(async event => {
      if (!event.data) return

      switch (event.data.type) {
        case 'ticknovate-payment-loading':
          setIs3DSecureSubmitting(true)
          break
        case 'ticknovate-payment-completed':
          completeOrderAndRedirect()
          break
        case 'ticknovate-payment-error':
          handleSagePayError(event.data.value)
          setIs3DSecureSubmitting(false)
          setDataFor3DSecureRedirect(null)
          break
      }
    }),
  )

  const handleSagePayError = (error) => {
    console.error('SagePay error', error)
    console.log('With cart', cartRef.current)

    if (error && error.content) {
      if (error.content.clientMessage) {
        triggerToastMessage({
          status: 'error',
          message: error.content.clientMessage,
        })
      } else if (error.content.paymentNotTaken) {
        triggerToastMessage({
          status: 'error',
          message: 'Your payment could not be completed. Please check your details and try again.',
        })
      }
    } else {
      // TODO: Should we do more here? Payment may be complete!
      triggerGenericToastError()
    }

    sessionKey.invalidate()
  }

  const loadSagePayForm = async () => {
    if (isApiUnavailable) return

    const merchantSessionKey = await sessionKey.getKey()
    const form = window.sagepayCheckout({
      merchantSessionKey,
      onTokenise: async ({ success, cardIdentifier }) => {
        // Redefine draftCart as this closure references potentially stale values.
        // TODO: Move this `loadSagePayForm` out of this component so it will be clearer what variables it depends upon. No closure.
        const cart = cartRef.current

        if (success) {
          setIsSubmitting(true)

          try {
            const paymentResponse = await cartApi.addPayment(
              cart,
              {
                method: 'sagepay_card',
                sessionDetails: {
                  cardIdentifier,
                  merchantSessionKey: sessionKey.getLastGeneratedKey(),
                  billingAddress,
                },
              },
            )

            const is3DSecureNeeded = paymentResponse.status === '3d-secure-authentication-required'

            if (is3DSecureNeeded) {
              // The response is an object with redirection information for 3D secure
              setDataFor3DSecureRedirect(paymentResponse)
            } else {
              // Congratulations, we have a successful payment!
              // Finalize order and then redirect.
              completeOrderAndRedirect()
            }
          } catch (err) {
            handleSagePayError(err)
          }
        } else {
          handleSagePayError()
        }

        setIsSubmitting(false)
      },
    })

    form.form({
      formSelector: '#sp-container',
    })

    return form
  }

  const destroySagePayForm = () => {
    if (sagePayForm.current && sagePayForm.current.destroy) {
      sagePayForm.current.destroy()
    }
  }

  useEffect(() => {
    let mounted = true

    const setSagePayForm = async () => {
      sagePayForm.current = await loadSagePayForm()
      if (!mounted) destroySagePayForm()
    }

    setSagePayForm()

    return () => {
      mounted = false
      destroySagePayForm()
    }
  }, [])

  if (isApiUnavailable) {
    return (
      <Fragment>
        <Notification status={'error'}>
          {t('billing.errors.CANT-LOAD-PAYMENT-FORM.long', 'The payment form could not load.')}
        </Notification>
        <Container styling={styles.paddingTop}>
          <ModalButton
            disabled
            color={'disabled'}
            content={t('billing.button-confirm-card', 'Complete payment')}
            data-test-handle={'btn-checkout-complete'}
          />
        </Container>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {/* http://integrations.sagepay.co.uk/content/using-3-d-secure */}
      {dataFor3DSecureRedirect && (
        <Iframe3DSecure
          initialHtml={dataFor3DSecureRedirect.redirectIframeHTML}
          loading={is3DSecureSubmitting}
        />
      )}
      <form onSubmit={e => e.preventDefault()}>
        <Container styling={styles.paddingTop}>
          <img
            src={'/sagepay-payment-logos.png'}
            alt={'Supported card providers: Maestro, American Express, Mastercard and Visa'}
          />
          <SagePayIframe />
        </Container>
        {termsAndConditions && (
          <Container styling={styles.paddingTop}>
            {termsAndConditions}
          </Container>
        )}
        <Container id={'submit-container'} styling={styles.paddingTop}>
          <ModalButton
            id={'complete-payment-button'}
            content={t('billing.button-confirm-card', 'Complete payment')}
            data-test-handle={'btn-checkout-complete'}
            color={dataFor3DSecureRedirect != null
              ? 'disabled'
              : 'green'
            }
            onClick={async () => {
              setIsSubmitting(true)
              const newMerchantSessionKey = await sessionKey.getKey()
              sagePayForm.current.tokenise({ newMerchantSessionKey })
              setIsSubmitting(false)
            }}
            loading={isSubmitting}
            disabled={isSubmitting || dataFor3DSecureRedirect != null}
          />
        </Container>
      </form>
    </Fragment>
  )
}

export default SagePayForm
