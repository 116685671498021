const safe = [
  'type',
  'product_id',
  'location',
  'end_location',
  'outbound_date',
  'inbound_date',
  'ticket',
]

const validateData = (meta) => ({
  type: (value) => {
    return value === 'route' || value === 'event'
  },
  product_id: (value) => {
    return meta.products.find(product => product.id === value)
  },
  location: (value) => {
    return meta.location_map.hasOwnProperty(value)
  },
  end_location: (value) => {
    return meta.location_map.hasOwnProperty(value)
  },
  ticket: (value) => {
    return value.every(ticket => meta.tickets_map.hasOwnProperty(ticket.id))
  },
})

const get_allowed_tickets = {
  'unique-route': (meta) => { // Unique ticket types as a list from all route products
    const filtered = meta.products
      .filter(product => product.type === 'route')
      .reduce((acc, { ticket_types }) => {
        ticket_types.forEach(type => {
          if (!acc.includes(type)) acc.push(type)
        })

        return acc
      }, [])

    return filtered
  },
  product: (meta, id) => { // Tickets from a product by id
    const filtered = meta.products
      .filter(product => product.id === id)
      .reduce((acc, { ticket_types }) => {
        ticket_types.forEach(type => {
          if (!acc.includes(type)) acc.push(type)
        })

        return acc
      }, [])

    return filtered
  },
  exact: (meta, id, config) => { // tickets from an exact list
    const filtered = meta.tickets
      .filter(ticket => config.exact.includes(ticket.value))
      .map(ticket => ticket.value)

    return filtered
  },
  all: (meta) => { // all tickets
    const filtered = meta.tickets
      .map(ticket => ticket.value)

    return filtered
  },
}

const getInitial = (type, meta, urlParams, layout) => {
  const {
    explore,
  } = window.TICKNOVATE_CONFIG

  const initial = {
    type,
    ticket: [],
  }

  if (!meta.isLoaded) {
    return initial
  }

  const validate = validateData(meta)

  Object.keys(urlParams)
    .forEach(key => {
      const valid = validate[key] ? validate[key](urlParams[key]) : true
      if (safe.includes(key) && valid) { // Key exists, safe to push in
        initial[key] = urlParams[key]
      }
    })

  const allowed_tickets = get_allowed_tickets[layout['ticket-pick']](meta, initial.product_id, explore[type])

  // Event doesn't use locations yet
  if (initial.type === 'event') {
    const product = meta.product_info_map[initial.product_id]

    initial.location = product?.locations[0]
    initial.outbound_date_selection = product?.next_service_date
    initial.end_location = undefined
  }

  // Kill locations if only partial
  if (initial.hasOwnProperty('location') || initial.hasOwnProperty('end_location')) {
    if (initial.end_location && !initial.hasOwnProperty('location')) {
      initial.end_location = undefined
    }

    if (initial.location && !initial.hasOwnProperty('end_location')) {
      initial.location = undefined
    }
  }

  // Kill dates if only partial
  if (initial.hasOwnProperty('inbound_date') && !initial.hasOwnProperty('outbound_date')) {
    initial.inbound_date = undefined
  }

  // Filter out tickets with available from allowed
  initial.ticket = allowed_tickets
    .map(ticket => {
      const exists = initial.ticket.find(item => item.id === ticket)

      return {
        id: ticket,
        qty: exists ? exists.qty : 0,
      }
    })

  // If we are showing Travel then derive if there is a group
  if (layout.selector === 'group') {
    const non_travellers = initial.ticket
      .filter(ticket => meta.tickets_group_map[ticket.id].id !== explore[type].travellerGroup)
      .filter(ticket => ticket.qty > 0)

    if (non_travellers.length > 0) {
      initial.group = meta.tickets_group_map[non_travellers[0].id] ? meta.tickets_group_map[non_travellers[0].id].entity_id : null
    }
  }

  return initial
}

export default getInitial
