import { useRef } from 'react'
import { listen, trigger } from '@ticknovate/frontend-shared/libs/events'
import differenceInSeconds from 'date-fns/difference_in_seconds'

const NAMESPACE = 'TICKNOVATE'

const SESSION_TIMEOUT = `${NAMESPACE}:SESSION_TIMEOUT`
const SESSION_STARTED = `${NAMESPACE}:SESSION_STARTED`
const SESSION_ENDED = `${NAMESPACE}:SESSION_ENDED`

const getSecondsFromNow = targetTime => {
  const difference = differenceInSeconds(targetTime, Date.now())
  return Math.max(difference, 0)
}

const useSessionTimeout = () => {
  const interval = useRef(null)
  const ended = useRef(null)

  const updateSecondsRemaining = () => {
    // Show message 90 sec earlier than expiry to  allow for drift
    const remaining = getSecondsFromNow(ended.current) - 90

    if (remaining === 0) {
      clearInterval(interval.current)

      trigger(SESSION_TIMEOUT)
    }
  }

  const startSession = ({ targetTime }) => {
    clearInterval(interval.current)

    ended.current = targetTime

    interval.current = setInterval(updateSecondsRemaining, 1000)
  }

  const endSession = () => {
    clearInterval(interval.current)

    ended.current = null
  }

  listen(SESSION_STARTED, startSession)
  listen(SESSION_ENDED, endSession)
}

const triggerStartSession = (targetTime) => {
  trigger(SESSION_STARTED, {
    targetTime,
  })
}

const triggerEndSession = () => {
  trigger(SESSION_ENDED, {})
}

export default useSessionTimeout

export {
  SESSION_TIMEOUT,
  SESSION_STARTED,
  SESSION_ENDED,
  triggerStartSession,
  triggerEndSession,
}
