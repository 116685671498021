import React from 'react'

import sortBy from 'lodash/sortBy'

import { useTranslation } from 'react-i18next'

import ModalView from '_/components/layout/ModalView'
import ModalColumn from '_/components/layout/ModalColumn'
import Area from '_/components/layout/Area'

import Container from '_/components/layout/Container'
import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'
import TextBox from '_/components/layout/TextBox'

import InputInteger from '_/components/input/InputInteger'

import StepRouter from '_/components/navigation/StepRouter'

import useRevert from '_/hooks/useRevert'

import calcMaxPax from '_/libs/calcMaxPax'

const TravelSimple = ({
  data,
  current,
  update,
  close,
}) => {
  const { t } = useTranslation()

  const revert = useRevert(current, update, true)

  const {
    explore,
  } = window.TICKNOVATE_CONFIG

  const layout = explore[current.type]

  let remaining = 999
  let options = sortBy(
    current.ticket
      .map(ticket => {
        const info = data.tickets.find(item => item.value === ticket.id)

        return {
          label: info.label,
          value: ticket.id,
        }
      }),
    'order',
  )

  if (layout.selector === 'group' || layout.selector === 'hire') {
    options = sortBy(
      current.ticket
        .filter(ticket => {
          return data.tickets_group_map[ticket.id].id === layout.travellerGroup
        })
        .map(ticket => {
          const info = data.tickets.find(item => item.value === ticket.id)

          return {
            label: info.label,
            value: ticket.id,
          }
        }),
      ['order'],
    )

    remaining = calcMaxPax(data, current.ticket, options.map(option => option.value))
  }

  if (layout.selector === 'exact') {
    options = sortBy(
      current.ticket
        .filter(ticket => {
          return layout.exact.includes(ticket.id)
        })
        .map(ticket => {
          const info = data.tickets.find(item => item.value === ticket.id)

          return {
            label: info.label,
            value: ticket.id,
          }
        }),
      ['label'],
    )
  }

  const handleChange = async (field, value) => {
    update(
      [
        {
          field,
          value,
        },
      ],
    )
  }

  const wrappedClose = () => {
    revert()
    close()
  }

  return (
    <ModalView close={wrappedClose}>
      <StepRouter
        save={close}
        routes={[
          {
            title: t('ticket.model-title-travellers'),
            icon: 'passenger',
            valid: true,
            view: ({ ...viewProps }) => (
              <ModalColumn {...viewProps}>
                <Area
                  areas={[
                    'list',
                    'info',
                  ]}
                  columns={1}
                  rowgap={0.5}
                >
                  <Container area={'list'}>
                    {options.map(option => {
                      const index = current.ticket.findIndex(ticket => ticket.id === option.value)

                      return (
                        <Row spread={false} margin={'0 0 1rem 0'} key={option.value}>
                          <InputInteger
                            field={`ticket[${index}]qty`}
                            value={index === -1 ? 0 : current.ticket[index].qty}
                            change={handleChange}
                            noFlex
                            disabled={remaining <= 0}
                          />
                          <InlineTextBox margin={'0 0 0 1rem'} strong size={'1.125'}>{option.label}</InlineTextBox>
                        </Row>
                      )
                    })}
                  </Container>
                  {remaining <= 0 && (
                    <TextBox strong color={'text_error'} area={'info'}>{t('ticket.warning-max-travellers')}</TextBox>
                  )}
                </Area>
              </ModalColumn>
            ),
          },
        ]}
      />
    </ModalView>
  )
}

export default TravelSimple