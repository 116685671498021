/* eslint-disable camelcase */
import React from 'react'

import sortBy from 'lodash/sortBy'

import { useTranslation } from 'react-i18next'

import ModalView from '_/components/layout/ModalView'
import ModalColumn from '_/components/layout/ModalColumn'
import Area from '_/components/layout/Area'

import Container from '_/components/layout/Container'
import Row from '_/components/layout/Row'
import InlineTextBox from '_/components/layout/InlineTextBox'
import TextBox from '_/components/layout/TextBox'

import InputInteger from '_/components/input/InputInteger'

import StepRouter from '_/components/navigation/StepRouter'

import useRevert from '_/hooks/useRevert'

import useBasket from '_/hooks/useBasket'

import calcMaxPax from '_/libs/calcMaxPax'

import { getSingularAvailability } from '_/hooks/useBookingOptions'

import makeSerialID from '_/libs/makeSerialID'

const getNextDate = (meta, product) => {
  const {
    product_info_map,
  } = meta

  const next_service_date = product_info_map[product]?.next_service_date

  return next_service_date
}

const Travellers = ({
  data,
  current,
  update,
  close,
}) => {
  const { t } = useTranslation()

  const {
    editBasket,
  } = useBasket()

  const revert = useRevert(current, update, true)

  const {
    explore,
  } = window.TICKNOVATE_CONFIG

  const layout = explore[current.type]

  const autoDate = getNextDate(data, current.product_id)

  let remaining = 999
  let options = sortBy(
    current.ticket
      .map(ticket => {
        const info = data.tickets.find(item => item.value === ticket.id)

        return {
          label: info.label,
          value: ticket.id,
          order: ticket.order,
        }
      }),
    ['order'],
  )

  if (layout.selector === 'group') {
    options = sortBy(
      current.ticket
        .filter(ticket => {
          return data.tickets_group_map[ticket.id].id === layout.travellerGroup
        })
        .map(ticket => {
          const info = data.tickets.find(item => item.value === ticket.id)

          return {
            label: info.label,
            value: ticket.id,
            order: ticket.order,
          }
        }),
      ['order'],
    )

    remaining = calcMaxPax(data, current.ticket, options.map(option => option.value))
  }

  if (layout.selector === 'exact') {
    options = sortBy(
      current.ticket
        .filter(ticket => {
          return layout.exact.includes(ticket.id)
        })
        .map(ticket => {
          const info = data.tickets.find(item => item.value === ticket.id)

          return {
            label: info.label,
            value: ticket.id,
            order: ticket.order,
          }
        }),
      ['order'],
    )
  }

  const handleChange = async (field, value) => {
    update(
      [
        {
          field,
          value,
        },
      ],
    )
  }

  const wrappedClose = () => {
    revert()
    close()
  }

  // TODO: Move this to top level ticket logic
  const wrappedSave = async () => {
    if (autoDate && current.type === 'event' && layout.autoSelect) {
      const options = await getSingularAvailability({
        types: current.type,
        product_id: current.product_id,
        dates: [autoDate],
        ticket: current.ticket.filter(({ qty }) => qty > 0),
        ...(current.itemId && { booking: current.itemId }),
      })

      if (options.length > 0) {
        const {
          product_id,
          instance_id,
          start_date,
          start_time,
          pricing,
        } = options[0]

        const basket_item = {
          ...(current.itemId && { id: current.itemId }),
          pick_id: makeSerialID(options[0]),
          product_id,
          combo_id: null,
          instance_id,
          start_date,
          start_time,
          ticket: current.ticket
            .filter(ticket => ticket.qty > 0),
          location: null,
          end_location: null,
          pricing,
        }

        editBasket(basket_item, 'outbound')

        update([
          {
            field: 'outbound_time',
            value: start_time,
          },
          {
            field: 'outbound_date',
            value: start_date,
          },
        ])
      }
    }

    close()
  }

  return (
    <ModalView close={wrappedClose}>
      <StepRouter
        save={wrappedSave}
        routes={[
          {
            title: t('ticket.model-title-travellers'),
            icon: 'passenger',
            valid: true,
            view: ({ ...viewProps }) => (
              <ModalColumn {...viewProps}>
                <Area
                  areas={[
                    'list',
                    'info',
                  ]}
                  columns={1}
                  rowgap={0.5}
                >
                  <Container area={'list'}>
                    {options.map(option => {
                      const index = current.ticket.findIndex(ticket => ticket.id === option.value)

                      return (
                        <Row spread={false} margin={'0 0 1rem 0'} key={option.value}>
                          <InputInteger
                            field={`ticket[${index}]qty`}
                            value={index === -1 ? 0 : current.ticket[index].qty}
                            change={handleChange}
                            noFlex
                            disabled={remaining <= 0}
                          />
                          <InlineTextBox margin={'0 0 0 1rem'} strong size={'1.125'}>{option.label}</InlineTextBox>
                        </Row>
                      )
                    })}
                  </Container>
                  {remaining <= 0 && (
                    <TextBox strong color={'text_error'} area={'info'}>{t('ticket.warning-max-travellers')}</TextBox>
                  )}
                </Area>
              </ModalColumn>
            ),
          },
        ]}
      />
    </ModalView>
  )
}

export default Travellers
