
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import get from 'lodash/get'
import isPast from 'date-fns/is_past'
import addDays from 'date-fns/add_days'
import isBefore from 'date-fns/is_before'
import isValid from 'date-fns/is_valid'
import isAfter from 'date-fns/is_after'
import startOfDay from 'date-fns/start_of_day'
import { getTicketPathnameForExplore } from '_/config/ticketsUrls'
import { openTicketForm } from './ticketDetailsURLUtils'
import getItemComponents from './getItemComponents'
import getServiceMeta from './getServiceMeta'

const normalizedProperties = [
  'reference',
  'reference_a',
  'editable',
  'editable_reason',
  'start_time',
  'item_id',
  'payable',
  'cancellation_amendment_fee',
]

export default class CartItem {
  constructor (parent, item) {
    Object.assign(
      this,
      // Remove any properties that we have getters for
      omit(item, normalizedProperties),
      {
        id: item.id,
        data: item,
        parent,
      },
    )
  }

  openEditForm () {
    openTicketForm({
      urlQuery: this.with_dependent_items.map(({ item_id }) => ({
        edit_item_id: item_id,
      })),
      pathname: getTicketPathnameForExplore(),
    })
  }

  get item_id () {
    return this.data.id
  }

  get start_time () {
    return `${this.data.booked_unit.start_date}T${this.data.booked_unit.start_time}Z` // Do we need to wrap in start_time as well?
  }

  get unit_type () {
    return this.data.booked_unit.type
  }

  get total () {
    return this.data.pricing.total
  }

  get parent_item () {
    if (this.parent_item_id == null) {
      return null
    }

    return this.parent.items.find(item => {
      return item.data.item_id === this.parent_item_id
    }) || null
  }

  get child_item () {
    return this.parent.items.find(item => {
      return item.data.parent_item_id === this.item_id
    }) || null
  }

  get related_item () {
    return this.parent_item || this.child_item
  }

  get ordered_counterpart () {
    return this.parent.sales_order.items.find(({ item_id }) => {
      return item_id === this.original_item_id
    })
  }

  get editable () {
    return get(this, 'parent.is_order_edit_cart')
      // Carts won't have an "editable" property - only orders. If undefined,
      // default to "editable": true.
      ? !!get(this, 'ordered_counterpart.data.editable', true)
      : !!get(this, 'data.editable', true)
  }

  get editable_reason () {
    return get(this, 'parent.is_order_edit_cart')
      ? get(this, 'ordered_counterpart.data.editable_reason', 'This item cannot be edited.')
      : this.data.editable_reason
  }

  get with_dependent_items () {
    const { parent_item, child_item } = this

    return parent_item
      ? [parent_item, this]
      : child_item
        ? [this, child_item]
        : [this]
  }

  get reference () {
    return this.data.reference || this.data.order_item_reference || null
  }

  get reference_a () {
    return this.data.reference_a || this.data.order_item_reference_a || null
  }

  get derived_product_meta () {
    return getServiceMeta(this.data)
  }

  get is_cancelled () {
    return this.status === 'cancelled'
  }

  get is_cancelling () {
    return this.status === 'cancelling'
  }

  get is_amending () {
    return this.status === 'amending'
  }

  get is_past () {
    const start = this.getAdjustedDate('start_time')

    const type = this.data.booked_unit.type

    if (type === 'date') {
      return isBefore(start, startOfDay(new Date()))
    }

    return this.derived_product_meta.startTimeUIType === 'date'
      ? isAfter(startOfDay(Date.now()), startOfDay(start))
      : isAfter(Date.now(), start)
  }

  get is_in_fee_free_period () {
    return this.fee_free_period_end != null && !isPast(this.getAdjustedDate('fee_free_period_end'))
  }

  get is_fee_free_period_almost_over () {
    return (
      this.is_in_fee_free_period &&
      (this.parent.is_order || this.parent.is_amending_order) &&
      isBefore(
        this.getAdjustedDate('fee_free_period_end'),
        addDays(Date.now(), 2),
      )
    )
  }

  get components () {
    return getItemComponents(this.data)
  }

  get payable () {
    return this.pricing.payable
  }

  get cancellation_amendment_fee () {
    return this.pricing.cancellation_amendment_fee
  }

  /**
   * When comparing this item to another, perhaps for `React.memo`, call this
   * method to get the core data for comparison.
   *
   * This is much more performant than letting lodash's `isEqual` function compare
   * the class instance as is, since it unnecessarily invokes all the getters.
   */
  getDataForMemoComparison () {
    return this.data
  }

  toJSON () {
    return this.data
  }

  toNormalizedObject () {
    return {
      ...this.data,
      ...pick(this, [...normalizedProperties, 'id']),
      // Pick properties needed to pass schema validation for legacy API endpoints.
      ...pick(this.parent.data, ['locale', 'currency']),
      parent: this.parent.toNormalizedObject(),
    }
  }

  /**
   * Get a date property that has been modified to account for a discrepancy
   * between the server time and the client device time.
   */
  getAdjustedDate (prop) {
    const date = new Date(
      new Date(get(this, prop)).getTime() - this.parent.server_time_offset,
    )

    if (!isValid(date)) {
      throw new Error('Invalid date when trying to apply offset')
    }

    return date
  }
}

export {
  getItemComponents,
}
