import { useRef } from 'react'

import {
  useQuery,
} from 'react-query'

import apiCaller from '_/libs/apiCaller'

import uniq from 'lodash/uniq'

import getStore from '_/store'

const fetch = window.fetch

const defaults = {
  isLoaded: false,
  market: null,
  locations_raw: [],
  locations: [],
  location_map: {},
  groups: [],
  group_map: {},
  tickets: [],
  tickets_map: {},
  tickets_group_map: {},
  tickets_by_group: {},
  products: [],
  product_map: {},
  product_info_map: {},
  combos: [],
  combo_map: {},
  combo_info_map: {},
  route_segments: [],
  route_segments_map: {},
}

// TODO: I hate this and it looks like a hack
const getOverview = async (endpoint) => {
  const {
    app,
  } = window.TICKNOVATE_CONFIG

  try {
    const options = {
      method: 'GET',
      headers: { 'Accept': 'application/json' },
    }

    if (app.auth) {
      const store = await getStore()
      options.headers.Authorization = store.getState().user.accessToken
    }

    const response = await fetch(endpoint, options)

    const data = await response.json()

    return data
  } catch (error) {
    console.log('Error', error)

    return null
  }
}

const getInitialMarketLocale = async () => {
  const {
    env,
    app,
  } = window.TICKNOVATE_CONFIG

  try {
    const {
      market,
    } = await getOverview(`${app.gatewayURL}/markets/${env.market}/overview`)

    if (market?.locales?.length > 0) {
      return market.locales[0].id
    } else {
      return null
    }
  } catch (error) {
    console.log('Overview fetch failed', error)

    return null
  }
}

const useOverview = () => {
  const {
    env,
    app,
  } = window.TICKNOVATE_CONFIG

  const errorSent = useRef(false)
  const getOverview = apiCaller(process.env.REACT_APP_OVERVIEW_OVERWRITE || `markets/${env.market}/overview`, app.auth)

  const {
    isFetching,
    isSuccess,
    isError,
    data,
  } = useQuery('product_overview', getOverview, {
    retry: 0,
    staleTime: 1000 * 60 * 60, // Keep me at 1 hour
  })

  if (isSuccess) {
    const {
      market,
      locations,
      ticket_type_groups,
      ticket_types,
      products,
      combos,
      route_segments,
    } = data

    const group_map = ticket_type_groups
      .reduce((list, group) => {
        list[group.entity_id] = group.title

        return list
      }, {})

    return {
      ...defaults,
      isFetching,
      isLoaded: isSuccess,
      market,
      locations_raw: locations,
      locations: locations
        .map(location => ({
          value: location.id,
          label: `${location.country}, ${location.title}`,
        })),
      location_map: locations
        .reduce((list, location) => {
          list[location.id] = `${location.country}, ${location.title}`

          return list
        }, {}),
      groups: ticket_type_groups
        .map(group => ({
          external_id: group.id,
          entity_id: group.entity_id,
          icon: group.icon,
          value: group.entity_id,
          label: group.title,
        })),
      group_map,
      tickets: ticket_types
        .map(ticket => ({
          group: ticket.ticket_type_group.entity_id,
          value: ticket.id,
          label: ticket.title,
          max: ticket.mandatory_companions_max_free_selection,
          raw_id: ticket.entity_id,
          order: ticket.order,
        })),
      tickets_map: ticket_types
        .reduce((list, ticket) => {
          list[ticket.id] = {
            short: ticket.title,
            long: `${group_map[ticket.ticket_type_group.entity_id]}, ${ticket.title}`,
            order: ticket.order,
          }

          return list
        }, {}),
      tickets_group_map: ticket_types
        .reduce((list, ticket) => {
          const group = ticket_type_groups.find(group => group.entity_id === ticket.ticket_type_group.entity_id)

          if (group) {
            list[ticket.id] = {
              id: group.id,
              entity_id: group.entity_id,
              icon: group.icon,
            }
          }

          return list
        }, {}),
      tickets_by_group: ticket_types
        .reduce((list, ticket) => {
          const group = ticket_type_groups.find(group => group.entity_id === ticket.ticket_type_group.entity_id)

          if (group) {
            if (list[group.id]) {
              list[group.id].push({
                ...ticket,
              })
            } else {
              list[group.id] = [{
                ...ticket,
              }]
            }
          }

          return list
        }, {}),
      products,
      product_map: products
        .reduce((list, product) => {
          list[product.id] = product.title

          return list
        }, {}),
      product_info_map: products
        .reduce((list, product) => {
          list[product.id] = product

          return list
        }, {}),
      combos,
      combo_map: combos
        .reduce((list, product) => {
          list[product.id] = product.title

          return list
        }, {}),
      combo_info_map: combos
        .reduce((list, product) => {
          list[product.id] = product

          return list
        }, {}),
      route_segments: route_segments
        .reduce((list, segment) => {
          const location = locations.find(location => location.id === segment.location)

          if (location) {
            list.push({
              value: location.id,
              label: `${location.country}, ${location.title}`,
              data: segment,
            })
          }

          return list
        }, []),
      route_segments_map: route_segments
        .reduce((list, { location, destinations }) => {
          list[location] = uniq(destinations)
            .map(id => {
              const locationMeta = locations.find(location => location.id === id)

              return {
                value: id,
                label: locationMeta ? `${locationMeta.country}, ${locationMeta.title}` : id,
              }
            })

          return list
        }, {}),
    }
  }

  if (isError && errorSent.current === false) {
    console.log('Error in fetch')
    errorSent.current = true
    return {
      isLoaded: isError,
      isFetching,
      ...defaults,
    }
  }

  return {
    isFetching,
    ...defaults,
  }
}

export default useOverview

export {
  getInitialMarketLocale,
}
