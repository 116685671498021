import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import ModalRouter from '_/components/navigation/ModalRouter'

import NavFlow from '_/components/navigation/NavFlow'
import StepLayout from '_/templates/StepLayout'
import { DetailsBox as Summary } from '_/templates/Summary'

import Area from '_/components/layout/Area'
import Container from '_/components/layout/Container'
import ActionCard from '_/components/action/ActionCard'
import { ResponsiveView as View } from '_/components/layout/View'

import Heading from '_/components/layout/Heading'
import TextBox from '_/components/layout/TextBox'

import { ActionBasketCart } from '_/components/action/ActionBasket'
import FooterWrapper from '_/components/layout/FooterWrapper'

import MediaQuery, { media } from '@ticknovate/frontend-shared/components/renderProp/MediaQuery'

import Basket from '_/modals/BasketCart'

import Message from './Message'
import ReducedForm from './ReducedForm'
import BillingForm from './BillingForm'
import Options from './Options'

import useForm from '_/hooks/useForm'
import useOverView from '_/hooks/useOverview'
import useCart from '_/hooks/useCart'

import useQuery from '_/hooks/useQuery'

import history from '@ticknovate/frontend-shared/libs/history'

import LoadIndicator from '_/templates/LoadIndicator'

import {
  getSchema,
  valid_spec,
} from './context'
import { triggerToastMessage } from '_/components/notification/Toaster'

const layout = {
  mobile: [
    'title',
    'message',
    'form',
    'options',
    'submit',
  ],
  tablet: [
    'title .',
    'message .',
    'form options',
    'form submit',
  ],
  desktop: [
    'title .',
    'message .',
    'form options',
    'form submit',
  ],
}

const billing_address = {
  'line_1': '',
  'line_2': '',
  'post_code': '',
  'country': '',
  'state': '',
}

const flowSteps = [
  {
    title: 'Your details',
    trans: 'details.flow-title',
    path: ['/complete/:id'],
  },
  {
    title: 'Make payment',
    trans: 'payment.flow-title',
    path: ['/payment', '/paylater'],
  },
]

const Complete = ({
  match,
}) => {
  const queries = useQuery()

  const { t } = useTranslation()

  const [
    billing,
    setBilling,
  ] = useState({
    ...billing_address,
  })

  const handleUpdateBilling = (field, value) => {
    const state = { ...billing }

    if (field === 'country' && value !== 'US') {
      state.state = ''
    }

    state[field] = value

    setBilling(state)
  }

  const cart = useCart()

  const meta = useOverView()

  const {
    app,
    details,
    payment,
  } = window.TICKNOVATE_CONFIG

  let initial = {}

  const exposeBilling = payment.enabledPaymentMethods.includes('sagepay_card')

  const {
    current,
    valid,
    allValid,
    changed,
    reset,
    update,
  } = useForm(getSchema, valid_spec, initial)

  useEffect(() => {
    cart.reclaim(match.params.id, queries.token)
  }, [])

  useEffect(() => {
    if (cart.data.ordered && !cart.data.draft_changes_pay_later) {
      triggerToastMessage({
        status: 'error',
        message: 'Order has already been paid.',
      })
      history.push('/expired')
    }
  }, [cart.data])

  useEffect(() => {
    if (cart.data.customer) {
      reset({
        customer: cart.data.customer,
        meta: {
          email_confirm: cart.data.customer.email,
        },
      })
    }
  }, [cart.data])

  const change = (field, value) => update([{ field, value }])

  const handleSubmit = async () => {
    const {
      customer,
      delivery_method,
    } = current

    const {
      id,
      token,
    } = cart.data

    await cart.update({
      id,
      token,
      customer,
      delivery_method,
    })

    history.push({
      pathname: '/payment',
      state: {
        billing,
        complete: true,
      },
    })
  }

  const valid_billing = Object.keys(billing)
    .reduce((acc, cur) => {
      if (cur === 'line_2') {
        acc[cur] = true
      } else {
        if (cur === 'state') {
          acc[cur] = billing.country === 'US' ? billing[cur] !== '' : true
        } else {
          acc[cur] = billing[cur] !== ''
        }
      }

      return acc
    }, {})

  let formValid = allValid

  if (exposeBilling) {
    formValid = allValid && Object.keys(valid_billing).every(key => valid_billing[key])
  }

  return (
    <MediaQuery media={media.mobile}>
      {mobile => {
        return (
          <ModalRouter
            mobile={mobile}
            routes={{
              basket: {
                desktop: {},
                mobile: {
                  height: 'calc(100vh - 2rem)',
                  bottom: 0,
                },
                view: ({
                  ...props
                }) => {
                  return (
                    <Basket {...props} data={meta} />
                  )
                },
              },
            }}
            render={mount => {
              return (
                <StepLayout mobile={mobile}>
                  <NavFlow
                    mobile={mobile}
                    match={match}
                    area={'nav'}
                    options={flowSteps}
                    title={t('paylater.section-title')}
                    back={false}
                  >
                    {app.basketInMenu && (
                      <ActionBasketCart
                        type={'menu'}
                        change={() => mount('basket')}
                        area={'child'}
                        gridAlign={'stretch'}
                      />
                    )}
                  </NavFlow>
                  <View mobile={mobile} area={'form'} gridAlign={'stretch'}>
                    <MediaQuery media={{ mobile: media.mobile, tablet: media.tablet, desktop: media.desktop }}>
                      {({ mobile, tablet, desktop }) => {
                        return (
                          <Area
                            areas={layout[mobile ? 'mobile' : tablet ? 'tablet' : 'desktop']}
                            columns={2}
                            rowgap={1}
                            colgap={mobile ? 0 : 1.5}
                          >
                            <Heading level={2} title={t('details.form-title')} area={'title'} />
                            {app.showLogin && (
                              <Message area={'message'} />
                            )}
                            <div area={'form'}>
                              <ReducedForm
                                mobile={tablet}
                                breakpoint={mobile ? 'mobile' : tablet ? 'tablet' : 'desktop'}
                                current={current}
                                valid={valid}
                                change={change}
                              >
                                {exposeBilling && (
                                  <BillingForm
                                    area={'billing'}
                                    breakpoint={mobile ? 'mobile' : tablet ? 'tablet' : 'desktop'}
                                    current={billing}
                                    valid={valid_billing}
                                    change={handleUpdateBilling}
                                  />
                                )}
                              </ReducedForm>
                            </div>
                            <div area={'options'}>
                              <Options current={current} change={change} />
                              <Submit
                                valid={formValid}
                                changed={changed}
                                action={handleSubmit}
                              />
                            </div>
                          </Area>
                        )
                      }}
                    </MediaQuery>
                    {cart.isFetching && (
                      <LoadIndicator />
                    )}
                  </View>
                  {!mobile && (
                    <Summary
                      key={'summary'}
                      area={'basket'}
                      basket={cart.data}
                      title
                    />
                  )}
                  {mobile && !app.basketInMenu && (
                    <FooterWrapper>
                      <ActionBasketCart
                        change={() => mount('basket')}
                      />
                    </FooterWrapper>
                  )}
                </StepLayout>
              )
            }}
          />
        )
      }}
    </MediaQuery>
  )
}

const Submit = ({
  valid,
  changed,
  action,
}) => {
  const { t } = useTranslation()

  const disabled = !changed

  return (
    <Container margin={'1.5rem 0 4rem 0'}>
      {changed && !valid && (
        <TextBox color={'text_error'}>{t('details.submit-error')}</TextBox>
      )}

      <ActionCard
        cta={valid && !disabled}
        label={t('meta.make-payment')}
        change={valid ? action : null}
        width={'100%'}
        warning={!valid}
        disabled={disabled}
      />
    </Container>
  )
}

export default Complete
