import { useTranslation } from 'react-i18next'

import { useEffect } from 'react'
import apiCaller from '_/libs/apiCaller'

import { useQuery, useQueryClient, useMutation } from 'react-query'

import { triggerStartSession } from '_/hooks/useSessionTimeout'
import { triggerToastMessageForError } from '_/components/notification/Toaster'
import { useDispatch } from 'react-redux'

import history from '@ticknovate/frontend-shared/libs/history'

import { syncBasketToCart } from '_/models/cart'

/**
 * Work with a cart.
 *
 * Pass `previewState` to see the changes applied on top of
 * the current cart (or a new cart, if no cart created).
 *
 * Use the mutations to persist these changes to an actual cart.
 */
const useCart = (previewState) => {
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const committedState = queryClient.getQueryData(['cart'])

  const dispatch = useDispatch()

  // Only send previewState if there is anything worth previewing
  if (previewState && previewState.items && Object.keys(previewState.items).every(key => previewState.items[key] === null)) {
    previewState = undefined
  }

  // Key of `['cart']` is the actual cart state, stored on server.
  // Key of `['cart', { items: [{ ... }] }]` is the result of previewing
  // the changes in the 2nd argument applied to the current cart.
  const queryKey = ['cart']
  if (previewState) {
    queryKey.push(previewState)
  }

  const query = useQuery(
    queryKey,
    () => {
      if (previewState) {
        return syncPreviewCart(syncBasketToCart(previewState, committedState))
      }

      return committedState
    },
    {
      retry: 0,
      keepPreviousData: true,
      staleTime: 0,
      enabled: !!(previewState || committedState),
    },
  )

  const { isSuccess, data, isFetching } = query

  useEffect(() => {
    if (query.isError) {
      if (query.error.message === 'CODE_FAILED_TO_APPLY') {
        triggerToastMessageForError({
          message: t('ticket.promo-error'),
        })

        dispatch({
          type: 'BASKET_SET_PROMO',
          data: null,
        })
      } else {
        console.log('ERROR', query)
        triggerToastMessageForError({
          message: t('meta.something-wrong'),
        })
      }
    }
  }, [query.isError])

  const defaultMutationOptions = {
    mutationKey: ['cart'],
    async onSuccess(data) {
      await queryClient.invalidateQueries('cart')
      queryClient.setQueryData(['cart'], data)
    },
    async onError(error) {
      console.error('Error in cart query', error)
      triggerToastMessageForError({
        message: t(`error.${error.message}`, error.message),
      })
    },
  }

  /*
    SPECIFIC CART OPERATIONS PAST PREVIEW STATE
  */
  const update = useMutation(
    async (body) =>
      updateCart(body),
    defaultMutationOptions,
  )

  const reclaim = useMutation(
    async ({ id, token }) => getCart(id, token),
    {
      ...defaultMutationOptions,
      onError(error) {
        console.error('Error in cart query', error)
        history.push('/expired')
      },
    },
  )

  const amend = useMutation(
    async ({ id, item_id = null }) => getAmendCart(id, item_id),
    {
      ...defaultMutationOptions,
      async onSuccess(data) {
        await queryClient.invalidateQueries('cart')
        queryClient.setQueryData(['cart'], data)

        return data
      },
    },
  )

  const discard = useMutation(
    async ({ id }) => discardCart(id),
    defaultMutationOptions,
  )

  // TODO: There are multiple layers here for seemingly no reason. Tidy.
  const voidItemFees = useMutation(
    async ({ id, item_id }) => apiCaller(`cart/${id}/items/${item_id}/void-fees`, true)({
      method: 'POST',
    }),
    defaultMutationOptions,
  )

  const reset = async () => {
    await queryClient.invalidateQueries('cart')
    queryClient.setQueryData(['cart'], null, {})
  }

  return {
    isFetching,
    isPreview: !!previewState,
    isLoaded: isSuccess,
    data: data || {
      items: [],
    },
    update: (data) => update.mutateAsync(data),
    reclaim: async (id, token) => reclaim.mutateAsync({ id, token }),
    amend: async (id, item_id) => amend.mutateAsync({ id, item_id }),
    discard: async (id) => discard.mutateAsync({ id }),
    voidItemFees: async (id, item_id) => voidItemFees.mutateAsync({ id, item_id }),
    reset,
  }
}

/**
 * Make changes on the current cart, or create a new cart
 * if it doesn't  already exist.
 */
const syncPreviewCart = (body) => {
  const {
    app,
  } = window.TICKNOVATE_CONFIG

  if (!body.id) {
    return apiCaller('cart?preview=1', app.auth)({
      body,
      method: 'POST',
    }).then((data) => {
      const { id, ...rest } = data

      return { id: null, ...rest }
    })
  } else {
    return apiCaller(`cart/${body.id}?preview=1`, app.auth)({
      body,
      method: 'PUT',
      headers: { 'cart-token': body.token },
    }).then((data) => {
      triggerStartSession(data.expiry_date)

      return data
    })
  }
}

const updateCart = ({id, ...body}) => {
  const {
    app,
  } = window.TICKNOVATE_CONFIG

  if (!id) {
    return apiCaller('cart', app.auth)({
      body,
      method: 'POST',
      headers: { 'cart-token': body.token },
    }).then((data) => {
      triggerStartSession(data.expiry_date)

      return data
    })
  } else {
    return apiCaller(`cart/${id}`, app.auth)({
      body,
      method: 'PUT',
      headers: { 'cart-token': body.token },
    }).then((data) => {
      triggerStartSession(data.expiry_date)

      return data
    })
  }
}

const getCart = (id, token) => {
  return apiCaller(`cart/${id}`)({
    method: 'GET',
    headers: { 'cart-token': token },
  }).then((data) => {
    triggerStartSession(data.expiry_date)

    return data
  })
}

const getAmendCart = (id, item_id) => {
  return apiCaller(`cart/${id}`, true)({
    method: 'GET',
  }).then((data) => {
    return {
      item_id,
      data,
    }
  })
}

const discardCart = (id) => {
  return apiCaller(`cart/${id}`, true)({
    method: 'DELETE',
  }).then((data) => {
    return data
  })
}

/**
 * When a cart exists, there is some state that cannot be changed,
 * and some massaging of the data that might need to occur.
 *
 * Here, we make those changes.
 */
// const mergeExistingCartIntoNewState = (existingState, newState) => {
//   console.log('MERGING STATES', existingState, newState)

//   if (existingState) {
//     newState = {
//       ...newState,
//       id: existingState.id,
//       token: existingState.token
//     }

//     if (newState.items && existingState.items.length > 0) {
//       newState = {
//         ...newState,
//         items: newState.items.map((item, i) => {
//           const committedCounterpart = existingState.items[i]
//           const id = committedCounterpart
//             ? committedCounterpart.id
//             : undefined

//           // Current frontend is positional - update the item
//           // at same position as one already in cart.
//           // This will need to change for amendments.
//           return { ...item, id }
//         })
//       }
//     }
//   }

//   return newState
// }

export default useCart

export {
  discardCart,
}
